// material
import { styled } from "@mui/material/styles";
// components
import Page from "../components/Page";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik, Field } from "formik";
import { useState } from "react";
import { baseUrl } from "src/config";

// material
import {
  Grid,
  Button,
  Link,
  Container,
  Checkbox,
  Stack,
  Box,
  Typography,
  Card,
  TextField,
  MenuItem,
} from "@mui/material";
// components
import { fCurrency, fNumber } from "../utils/formatNumber";
import firebase from "../firebase";
import emailjs from "@emailjs/browser";
import ReactPDF from "@react-pdf/renderer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InvoicePDF from "./pages/InvoicePDF";
import useConstants from "../hooks/useConstants";
import { LoadingButton } from "@mui/lab";
import TransitionsDialogs from "src/components/NotifContainer";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Welcome() {
  const settings = useConstants().docs;
  const notify = (msg) => toast(msg);
  const [officeValues, setOfficeValues] = useState(null);
  const [residentialValues, setresidentialValues] = useState(null);
  const [warehouseValues, setWarehouseValues] = useState(null);
  const [type, setType] = useState(null);
  const [progress, setprogress] = useState(false);
  const [msg, setmsg] = useState("");
  const [triggerNotif, setTriggerNotif] = useState(false);

  const [checked, setChecked] = useState(false);
  let publicKey = "WffsPR6cSS6IwClRB";
  const [options] = useState([null, "Commercial", "Retail", "Residential", "Mixed Used", "Industrial"]);

  const officeSchema = Yup.object().shape({
    project: Yup.string().required("Enter a value"),
    client: Yup.string().required("Enter a value"),
    email: Yup.string().required("Enter a value").email(),
    phone: Yup.number().required("Enter a value"),
    type: Yup.string().required("Enter a value"),
  });

  const officeformik = useFormik({
    initialValues: {
      project: "",
      client: "",
      email: "",
      phone: "",
      type: "",
      ...(officeSchema.type === "Office" || officeSchema.type === "Warehouse"
        ? { gfa: "", PMS_Enabled: false }
        : { typology: "", units: "" }),
    },
    validationSchema: officeSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        console.log(values.type, "here");
        
        if (values?.type === "Commercial" || values?.type === "Retail" || values?.type === "Mixed Used" ) {
         // BASED ON OFFICE CALCULATION
          setWarehouseValues(null);
          setresidentialValues(null);
          await calculate();
        } else if (values?.type === "Residential") {
           // BASED ON RES CALCULATION
          setWarehouseValues(null);
          setOfficeValues(null);
          await calculateR();
        } else if (values?.type === "Warehouse" || values?.type === "Industrial") {
           // BASED ON WAREHOUSE CALCULATION
          setresidentialValues(null);
          setOfficeValues(null);
          await calculateW();
        }
      } catch (error) {
        console.log(error, "here");
        setSubmitting(false);
      }
    },
  });

  const { errors, values, touched, handleSubmit, isSubmitting, getFieldProps } =
    officeformik;

  async function getBlob(doc, data, id) {
    setmsg("Creating PDF");
    const blob = await ReactPDF.pdf(<InvoicePDF data={data} />)
      .toBlob()
      .then((res) => {
        sendEmail(res, doc, id);
      })
      .catch((err) => {
        console.log(err);
      });
    return blob;
  }

  const sendEmail = (blob, quote, id) => {
    setmsg("Sending Email");
    let storageRef = firebase.storage().ref().child(`${id}_SOLID_EDGE_QUOTE`);
    storageRef.put(blob).on(
      "state_changed",
      (snap) => {
        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        console.log(percentage);
      },
      (err) => {
        notify(err);
      },
      async () => {
        var file = await convertBlobToBase64(blob);
        const url = await storageRef.getDownloadURL();
        firebase
          .firestore()
          .collection("quotes")
          .doc(quote.id)
          .update({
            quote_file: url,
          })
          .then((doc) => {
            let totals = null;

            if (values?.type === "Commercial" || values?.type === "Retail" || values?.type === "Mixed Used") {
              totals = officeValues.TotalFees;
            }
            if (values?.type === "Warehouse" || values?.type === "Industrial") {
              totals = warehouseValues.TotalFees;
            }
            if (values.type === "Residential") {
              totals = residentialValues.TotalFees;
            }

            const invoiceUrl = `${baseUrl()}/invoice/${quote.id}`;

            let obj = {
              id: id,
              invoiceUrl,
              email: values.email,
              client: values.client,
              project: values.project,
              total: totals,
              breakdown:
                values.type === "Office"
                  ? `Gross Floor Area ${values.gfa}`
                  : `Topology : ${values.typology}, Units : ${values.units}`,
              link: url,
              content: file,
            };

            setmsg("Uploading files to server");
            emailjs
              .send("service_6a716og", "template_galhv3r", obj, publicKey)
              .then(
                (result) => {
                  setmsg("Email has been sent.");
                  //   setValues([])
                  setresidentialValues(null);
                  setOfficeValues(null);
                  setmsg("");
                  setprogress(false);
                  setTriggerNotif(true);
                },
                (error) => {
                  console.log(error.text);
                  setprogress(false);
                }
              );
          });

        console.log(url);
      }
    );
  };

  const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const convertBlobToBase64 = async (blob) => {
    // blob data
    return await blobToBase64(blob);
  };

  // calculate for office
  const calculateOffice = (gfa) => {
    let officeValues = settings[0];

    console.log(officeValues, "office values");
    console.log(values, "check for values");

    let DPC =
      (0.0000000009 * Math.pow(parseFloat(gfa), 3) -
        0.00007 * Math.pow(parseFloat(gfa), 2) +
        2.6441 * parseFloat(gfa) +
        20629) *
      officeValues.PC;

    let data = {
      SC: parseFloat((DPC * officeValues?.SC).toFixed(2)),
      DPC: parseFloat(DPC.toFixed(2)),
      DPCC: parseFloat((DPC * officeValues?.DPCC).toFixed(2)),
      ExpertServices:
        parseFloat((DPC * officeValues?.SC).toFixed(2)) +
        parseFloat(DPC.toFixed(2)) +
        parseFloat((DPC * officeValues?.DPCC).toFixed(2)),
      PDA: parseFloat((DPC * officeValues?.PDA).toFixed(2)),
      PCSA: parseFloat((DPC * officeValues?.PCSA).toFixed(2)),
      AuditorServices:
        parseFloat((DPC * officeValues?.PDA).toFixed(2)) +
        parseFloat((DPC * officeValues?.PCSA).toFixed(2)),
      PMS: checked ? parseFloat((DPC * officeValues?.PMS).toFixed(2)) : 0,
      PMS_Enabled: checked,
      TotalFees:
        parseFloat((DPC * officeValues?.SC).toFixed(2)) +
        parseFloat(DPC.toFixed(2)) +
        parseFloat((DPC * officeValues?.DPCC).toFixed(2)) +
        parseFloat((DPC * officeValues?.PDA).toFixed(2)) +
        parseFloat((DPC * officeValues?.PCSA).toFixed(2)) +
        (checked && parseFloat((DPC * officeValues?.PMS).toFixed(2))),
    };

    setOfficeValues(data);
    setType(0);
    return data;
  };

  // calculate for residential
  const calculateResidential = (typology, units) => {
    let officeValues = settings[1];
    let eight = units / typology;
    let DPC;
    let ES =
      16555 +
      officeValues.DPC * parseInt(typology) +
      officeValues.DCPCC * parseInt(typology);

    DPC = parseFloat(officeValues.DPC * typology).toFixed(2) * 1;
    const PA_ = parseFloat(officeValues.DPC * officeValues.PA * typology);
    const PCA_ = parseFloat(
      officeValues.PCA * parseFloat(typology * (Math.sqrt(eight) + 1))
    );

    let AS = PA_ + PCA_ + (checked && parseFloat(DPC * officeValues?.PMS));

    let data = {
      ExpertServices: ES,
      AuditUnits: parseFloat(typology * (Math.sqrt(eight) + 1)).toFixed(0),
      IA: 16555,
      DPC: parseFloat(officeValues.DPC * typology).toFixed(2),
      DCPCC: parseFloat(officeValues.DCPCC * typology).toFixed(2),
      AuditorServices: AS,
      PA: parseFloat(officeValues.DPC * officeValues.PA * typology).toFixed(2),
      PCA: parseFloat(
        officeValues.PCA * parseFloat(typology * (Math.sqrt(eight) + 1))
      ).toFixed(2),
      PMS: checked ? parseFloat((DPC * officeValues?.PMS).toFixed(2)) : 0,
      TotalFees: (ES + AS).toFixed(2),
    };

    console.log(data);
    setresidentialValues(data);
    return data;
  };

  // calculate for warehouse
  const calculateWarehouse = (gfa) => {
    setOfficeValues(null);
    setresidentialValues(null);
    let officeValues = settings[2];

    let DPC =
      (0.0000000009 * Math.pow(parseFloat(gfa), 3) -
        0.00007 * Math.pow(parseFloat(gfa), 2) +
        2.6441 * parseFloat(gfa) +
        20629) *
      officeValues.PC;

    let data = {
      SC: parseFloat((DPC * officeValues?.SC).toFixed(2)),
      DPC: parseFloat(DPC.toFixed(2)),
      DPCC: parseFloat((DPC * officeValues?.DPCC).toFixed(2)),
      ExpertServices:
        parseFloat((DPC * officeValues?.SC).toFixed(2)) +
        parseFloat(DPC.toFixed(2)) +
        parseFloat((DPC * officeValues?.DPCC).toFixed(2)),
      PDA: parseFloat((DPC * officeValues?.PDA).toFixed(2)),
      PCSA: parseFloat((DPC * officeValues?.PCSA).toFixed(2)),
      AuditorServices:
        parseFloat((DPC * officeValues?.PDA).toFixed(2)) +
        parseFloat((DPC * officeValues?.PCSA).toFixed(2)),
      PMS: checked ? parseFloat((DPC * officeValues?.PMS).toFixed(2)) : 0,
      PMS_Enabled: checked,
      TotalFees:
        parseFloat((DPC * officeValues?.SC).toFixed(2)) +
        parseFloat(DPC.toFixed(2)) +
        parseFloat((DPC * officeValues?.DPCC).toFixed(2)) +
        parseFloat((DPC * officeValues?.PDA).toFixed(2)) +
        parseFloat((DPC * officeValues?.PCSA).toFixed(2)) +
        (checked && parseFloat((DPC * officeValues?.PMS).toFixed(2))),
    };

    setWarehouseValues(data);
    return data;
  };

  const calculate = () => {
    setresidentialValues(null);
    calculateOffice(values.gfa);
  };

  const calculateR = () => {
    setOfficeValues(null);
    calculateResidential(values.typology, values.units);
  };

  const calculateW = () => {
    if (values.gfa === undefined || values.email === undefined) {
      alert("Enter a GFA value");
      return;
    }
    calculateWarehouse(values.gfa);
  };

  const createQuote = () => {
    const id = Date.now();
    setprogress(true);
    setmsg("Putting things together");

    let asd = {
      ...values,
      quote_id: id,
      status: "NEW",
      quote_source: "INBOUND",
      ...calculateOffice(values.gfa),
      createdAt: new Date(Date.now()).toLocaleDateString(),
    };

    console.log(asd);
    firebase
      .firestore()
      .collection("quotes")
      .add(asd)
      .then((doc) => {
        setmsg("Sending data to the Database");
        return getBlob(doc, asd, id);
      });
  };

  const createWarehouseQuote = () => {
    const id = Date.now();
    setprogress(true);
    setmsg("Putting things together");

    let asd = {
      ...values,
      quote_id: id,
      status: "NEW",
      quote_source: "INBOUND",
      ...calculateWarehouse(values.gfa),
      createdAt: new Date(Date.now()).toLocaleDateString(),
    };

    console.log(asd);
    firebase
      .firestore()
      .collection("clients")
      .add(asd)
      .then((doc) => {
        setmsg("Sending data to the Database");
        return getBlob(doc, asd, id);
      });
  };

  const stripClientData = (data) => {
    // split the name into first and last name
    let name = data.name.split(" ");

    const [first, ...last] = name;

    let clientData = {
      firstName: first,
      lastName: last?.join(" "),
      email: data.email,
      phone: data.phone,
    };
    return clientData;
  };
  const createResidentialQuote = () => {
    const id = Date.now();
    setprogress(true);
    setmsg("Putting things together");
    if (values.email === null || values.email === "" || values.gfa === null) {
      notify("Enter all the values required");
      return;
    }
    let asd = {
      ...values,
      quote_id: id,
      status: "NEW",
      quote_source: "INBOUND",
      ...calculateResidential(values.typology, values.units),
      createdAt: new Date(Date.now()).toLocaleDateString(),
    };

    // create client
    firebase
      .firestore()
      .collection("clients")
      .add(stripClientData(asd))
      .then((doc) => {
        setmsg("Adding client to the Database");
        firebase
          .firestore()
          .collection("quotes")
          .add(asd)
          .then((doc) => {
            setmsg("Sending data to the Database");
            return getBlob(doc, asd, id);
          });
      });
  };
  //const mailjet = require('node-mailjet').connect('3a58c605327013b83caf0e1438e5baea', "07af45d194751db9430a4c885238832d");
  const handleChange1 = (event) => {
    setChecked(event.target.checked);
  };

  // alert("test 1")
  return (
    <RootStyle
      title="Welcome"
      style={{
        backgroundImage: `url('/static/bck.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      <TransitionsDialogs
        open={triggerNotif}
        setOpen={setTriggerNotif}
        title="Quote sent to email"
        description="We have sent a quotation to your email, Kindly check and follow the instruction. Thank you"
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <nav
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 20,
            padding: 20,
          }}
        >
          <Link href="/authenticate/client" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: 20 }}
            >
              Client login
            </Button>
          </Link>

          <Link href="/login" style={{ textDecoration: "none" }}>
            <Button
              variant="outlined"
              color="warning"
              style={{ marginRight: 20 }}
            >
              Admin login
            </Button>
          </Link>
        </nav>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {progress && (
        <div
          style={{
            position: "absolute",
            zIndex: 10000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "white",
          }}
        >
          <Stack direction="column" alignItems="center" justifyContent="center">
            <Typography variant="h4">Processing...</Typography>
            <Typography variant="body1">{msg}</Typography>
          </Stack>
        </div>
      )}

      <Container maxWidth="md">
        <FormikProvider value={officeformik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8}>
                <Stack spacing={3}>
                  <Card sx={{ p: 3 }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      mb={5}
                    >
                      <img
                        src="/static/SolidGreen-Logo.svg"
                        alt="login"
                        style={{ width: 128, marginBottom: 20 }}
                      />
                      <Typography variant="h4" gutterBottom></Typography>
                    </Stack>
                    <Stack spacing={3}>
                      <Stack direction="row" spacing={2}>
                        <TextField
                          fullWidth
                          {...getFieldProps("project")}
                          error={Boolean(touched.project && errors.project)}
                          helperText={touched.project && errors.project}
                          label="Project Name"
                        />
                        <TextField
                          fullWidth
                          {...getFieldProps("client")}
                          error={Boolean(touched.client && errors.client)}
                          helperText={touched.client && errors.client}
                          label="Client Name"
                        />
                      </Stack>

                      <Stack direction="row" spacing={2}>
                        <TextField
                          fullWidth
                          type="email"
                          {...getFieldProps("email")}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          label="Email Address"
                        />
                        <TextField
                          fullWidth
                          type="number"
                          {...getFieldProps("phone")}
                          error={Boolean(touched.phone && errors.phone)}
                          helperText={touched.phone && errors.phone}
                          label="Phone Number"
                        />
                      </Stack>

                      <TextField
                        fullWidth
                        select
                        {...getFieldProps("type")}
                        error={Boolean(touched.type && errors.type)}
                        helperText={touched.type && errors.type}
                        label="Building Type"
                      >
                        {options.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                      {values.type === null
                        ? null
                        : values.type === "Commercial" || values.type === "Retail"  || values.type === "Mixed Used"  && (
                            <TextField
                              fullWidth
                              type="number"
                              {...getFieldProps("gfa")}
                              error={Boolean(touched.gfa && errors.gfa)}
                              helperText={touched.gfa && errors.gfa}
                              label="Gross Floor Area"
                            />
                          )}

                      {values.type === null
                        ? null
                        : values.type === "Warehouse" ||values.type === "Industrial"  && (
                            <TextField
                              fullWidth
                              type="number"
                              {...getFieldProps("gfa")}
                              error={Boolean(touched.gfa && errors.gfa)}
                              helperText={touched.gfa && errors.gfa}
                              label="Gross Floor Area"
                            />
                          )}

                      {values.type === null
                        ? null
                        : values.type === "Residential" && (
                            <Stack spacing={3}>
                              <TextField
                                fullWidth
                                type="number"
                                {...getFieldProps("typology")}
                                error={Boolean(
                                  touched.typology && errors.typology
                                )}
                                helperText={touched.typology && errors.typology}
                                label="Typologies"
                              />
                              <TextField
                                fullWidth
                                type="number"
                                {...getFieldProps("units")}
                                error={Boolean(touched.units && errors.units)}
                                helperText={touched.units && errors.units}
                                label="Units in Development"
                              />
                            </Stack>
                          )}

                      <Stack
                        spacing={3}
                        direction="row"
                        style={{ alignItems: "center" }}
                      >
                        <Checkbox checked={checked} onChange={handleChange1} />{" "}
                        <Typography variant="body1" gutterBottom>
                          Performance Modelling
                        </Typography>
                      </Stack>
                      {/* {values.type === "Office" ||
                      values.type === "Warehouse" ? (
                        <Stack
                          spacing={3}
                          direction="row"
                          style={{ alignItems: "center" }}
                        >
                          <TextField
                            fullWidth
                            select
                            {...getFieldProps("PMS_Enabled")}
                            error={Boolean(touched.type && errors.type)}
                            helperText={touched.type && errors.type}
                            label="Performance Modelling"
                          >
                            <MenuItem value={true}>True</MenuItem>
                            <MenuItem value={false}>False</MenuItem>
                          </TextField>
                        </Stack>
                      ) : null} */}
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        size="large"
                        loading={isSubmitting}
                      >
                        Calculate
                      </LoadingButton>
                      {msg}
                    </Stack>
                  </Card>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                {officeValues && (
                  <Stack spacing={3}>
                    <Card sx={{ p: 3 }}>
                      <Typography variant="body1" gutterBottom>
                        Edge Consulting Services
                      </Typography>
                      <Typography variant="h3" gutterBottom>
                        R {fNumber(officeValues.TotalFees)}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Excludes VAT.
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        Click the button below to get the full price and payment
                        breakdown
                      </Typography>

                      <Button
                        fullWidth={true}
                        variant="contained"
                        onClick={() => createQuote()}
                      >
                        Email Quote <br /> (Office Assessment)
                      </Button>
                    </Card>
                  </Stack>
                )}

                {warehouseValues && (
                  <Stack spacing={3}>
                    <Card sx={{ p: 3 }}>
                      <Typography variant="body1" gutterBottom>
                        Edge Consulting Services
                      </Typography>
                      <Typography variant="h3" gutterBottom>
                        R {fNumber(warehouseValues.TotalFees)}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Excludes VAT.
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        Click the button below to get the full price and payment
                        breakdown
                      </Typography>

                      <Button
                        fullWidth={true}
                        variant="contained"
                        onClick={() => createWarehouseQuote()}
                      >
                        Email Quote <br /> (Warehouse Assessment)
                      </Button>
                    </Card>
                  </Stack>
                )}

                {residentialValues && (
                  <Stack spacing={3}>
                    <Card sx={{ p: 3 }}>
                      <Typography variant="body1" gutterBottom>
                        Edge Consulting Services
                      </Typography>
                      <Typography variant="h3" gutterBottom>
                        R {fNumber(residentialValues.TotalFees)}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Excludes VAT.
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        Click the button below to get the full price and payment
                        breakdown
                      </Typography>

                      <Button
                        fullWidth={true}
                        variant="contained"
                        onClick={() => createResidentialQuote()}
                      >
                        Email Quote
                        <br /> (Residential Assessment)
                      </Button>
                    </Card>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </RootStyle>
  );
}
